import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="form-select"
export default class extends Controller {
  connect() {
    if(this.element.multiple) {
      this.element.style.paddingLeft = this.element.labels[0].offsetWidth + 'px';
      this.element.style.paddingTop = '0.5rem';
    }
  }
}
