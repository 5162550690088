import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="phone"
export default class extends Controller {
  connect() {
    new Cleave(this.element, {
      phone: true,
      phoneRegionCode: 'BR'
    });
  }
}
