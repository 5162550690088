import { Controller } from "@hotwired/stimulus"
import * as bootstrap from "bootstrap";

// Connects to data-controller="tooltip"
export default class extends Controller {
  connect() {
    new bootstrap.Tooltip(this.element)
  }

  disconnect() {
    bootstrap.Tooltip.getInstance(this.element).hide();
  }
}
